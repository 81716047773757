<template>
	<div>
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="名称：">
					<el-autocomplete v-model.trim="formInline.name" :fetch-suggestions="querySearchAsync" clearable placeholder="请输入内容"></el-autocomplete>
				</el-form-item>
				<el-form-item label="省：">
					<el-select v-model="formInline.economize" placeholder="请选择省" @change="changeEconomize($event, 1)">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select v-model="formInline.market" placeholder="请选择市" @change="changeEconomize($event, 2)">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select v-model="formInline.regionCode" placeholder="请选择区县">
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="年份：">
					<el-date-picker v-model="formInline.reportYear" type="year" placeholder="选择年份" value-format="yyyy"> </el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<div class="changeTabs">
					<span :class="{ activeClass: checkTab == true }" @click="changeCheckTab(true)">智能</span>
					<span :class="{ activeClass: checkTab == false }" @click="changeCheckTab(false)">人工</span>
				</div>
			</div>
			<el-table :data="tableData" style="width: 100%" :height="tableHeight + 'px'" border>
				<el-table-column type="index" label="序号" width="50" fixed> </el-table-column>
				<el-table-column label="乡镇卫生院名称" width="160">
					<template slot-scope="scope">{{ scope.row.title }}</template>
				</el-table-column>
				<el-table-column label="诊疗人次数" width="140">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.personVisitNumber }}</span> |
						<span style="color: #f56c6c">{{ scope.row.personVisitNumberScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="诊疗人次增长率" width="160">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.personVisitRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.personVisitRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="中医诊疗人次占比" width="160">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.tcmPersonVisitRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.tcmPersonVisitRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="门诊中医非药物疗法诊疗人次" width="210">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.tcmNonDrugOutpatientNumber }}</span> |
						<span style="color: #f56c6c">{{ scope.row.tcmNonDrugOutpatientNumberScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="出院人数" width="120">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.dischargedPatientNumber }}</span> |
						<span style="color: #f56c6c">{{ scope.row.dischargedPatientNumberScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="病床使用率" width="140">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.bedsUtilizationRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.bedsUtilizationRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="65岁及以上老年人城乡社区规范健康管理服务率" width="320">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.sixtyfiveElderHealthServiceRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.sixtyfiveElderHealthServiceRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="高血压患者基层规范管理服务率" width="220">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.hypertensionHealthServiceRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.hypertensionHealthServiceRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="管理人群血压控制率" width="160">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.hypertensionQualifiedRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.hypertensionQualifiedRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="2型糖尿病患者基层规范管理服务率" width="260">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.diabetesHealthServiceRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.diabetesHealthServiceRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="管理人群血糖控制率" width="160">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.diabetesQualifiedRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.diabetesQualifiedRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="严重精神障碍患者规范管理率" width="220">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.psychosisHealthServiceRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.psychosisHealthServiceRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="家庭医生签约服务全人群覆盖率" width="220">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.familyDoctorContractRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.familyDoctorContractRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="医师日均担负诊疗人次" width="180">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.visitsAverageNumber }}</span> |
						<span style="color: #f56c6c">{{ scope.row.visitsAverageNumberScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="医疗服务收入占比" width="160">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.medicalServiceIncomeRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.medicalServiceIncomeRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="每职工平均本期盈余" width="160">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.employeeAverageCurrentSurplusPer }}</span> |
						<span style="color: #f56c6c">{{ scope.row.employeeAverageCurrentSurplusPerScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="人员支出占业务支出比例" width="180">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.operationalPersonnelExpenditureRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.operationalPersonnelExpenditureRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="执业医师占比" width="120">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.medicalPractitionersRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.medicalPractitionersRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="医护比" width="80">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.physicianNurseRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.physicianNurseRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="中医类别医师占比" width="160">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.tcmPhysicianRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.tcmPhysicianRateScore }}</span>
					</template>
				</el-table-column>
				<el-table-column label="高级职称卫生技术人员占比" width="220">
					<template slot-scope="scope">
						<span style="color: #31b948">{{ scope.row.seniorHealthTechniciansRate * 100 + '%' }}</span> |
						<span style="color: #f56c6c">{{ scope.row.seniorHealthTechniciansRateScore }}</span>
					</template>
				</el-table-column>
			</el-table>
			<div class="page-box">
				<el-pagination
					background
					layout="total, sizes, prev, pager, next, jumper"
					:page-sizes="[10, 20, 30, 100]"
					:page-size="queryData.pageSize"
					:current-page="queryData.currentPage"
					:total="total"
					@size-change="sizeChange"
					@current-change="currentChange"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'performanceManager',
	components: {},
	data() {
		return {
			formInline: {
				name: '',
				economize: null,
				market: null,
				regionCode: null,
				reportYear: null,
			},
			checkTab: true,
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			districtList: [], //区县
			marketList: [], //市
			economizeList: [], //省
			options: [],
			regionCode: null,
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getEconomizeList();
		this.getList();
	},
	methods: {
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: null,
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			this.regionCode = data;
			switch (type) {
				case 1:
					this.formInline.market = null;
					this.formInline.regionCode = null;
					this.marketList = [];
					this.districtList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.regionCode = null;
					this.districtList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api['ChinaRegions#index'].href, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: null,
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 查询条件
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},

		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			let data = {
				name: this.formInline.name,
				regionCode: this.formInline.regionCode ? this.formInline.regionCode : this.regionCode,
				...this.queryData,
				beingReality: this.checkTab,
				reportYear: this.formInline.reportYear ? this.formInline.reportYear + '-01-01 00:00:00' : '',
			};
			this.$http
				.get(this.api['PerformanceEvaluationResults#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.medicalOrganization?.title,
								};
							});
						} else {
							this.tableData =
								res.data.collection.map((v) => {
									return {
										...v,
										title: v.medicalOrganization?.title,
									};
								}) || [];

							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		sizeChange(val) {
			this.queryData.size = val;
			this.queryData.current = 1;
			this.getList();
		},
		currentChange(val) {
			this.queryData.current = val;
			this.queryData.size = this.queryData.size;
			this.getList();
		},
		changeCheckTab(val) {
			this.checkTab = val;
			this.getList();
		},
	},
};
</script>

<style lang="scss" scoped>
.demo-form-inline {
	/deep/ .el-select {
		width: 120px !important;
		.el-input {
			width: 120px !important;
			.el-input__inner {
				width: 120px !important;
			}
		}
	}
	/deep/ .el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		position: relative;
		.changeTabs {
			border: 1px solid #dcdfe6;
			border-radius: 6px;
			line-height: 32px;
			height: 32px;
			position: absolute;
			right: 0;
			top: 8px;
			span {
				padding: 5px 16px;
				cursor: pointer;
			}
		}
		.activeClass {
			background-color: #eff4ff;
			color: #1db9b1;
		}
	}
}
/deep/ .el-table {
	.cell {
		color: #333;
		font-weight: normal;
		text-align: center;
	}
	tr th {
		background-color: rgb(245, 245, 245);
	}
}
.page-box {
	height: 80px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}
</style>